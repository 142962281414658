<template>
  <div class="container">
    <nav-bar header-title="企业员工福利选超鹿" backColor="#fff" :show-back="true" :header-back="appBack" :showShare="true" @right-txt-click="showShare = true" />
    <div class="col-start-center">
      <img src="https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-1.png" alt="">

      <div class="btn-box btn-1 col-start-center">
        <div class="b1" @click="goService"></div>
        <div @click="call" class="b2"></div>
      </div>
      <img src="https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-3.png" alt="">
      <img src="https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-4.png" alt="">
      <div class="btn-box btn-2 col-start-center">
        <div class="b1" @click="goService"></div>
        <div @click="call" class="b2"></div>
      </div>
      <img src="https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-6.png" alt="">
      <img src="https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-7.png" alt="">
      <div class="btn-box btn-3 col-start-center">
        <div class="b1" @click="goService"></div>
        <div @click="call" class="b2"></div>
      </div>
    </div>
    <!--  分享  -->
    <common-share
      :value="showShare"
      :shareParams="shareParams"
      :shareItem="['minifriend']"
      @close="showShare = false"
      @share-success="showShare = false"
    >
    </common-share>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, gotoCustomerService, call } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare'
import wx from 'weixin-js-sdk'
export default {
  mixins: [userMixin],
  components: {
    navBar, commonShare
  },
  data() {
    return {
      showShare: false,
      shareParams: {
        title: '超鹿会员企业福利',
        miniImage: 'https://img.chaolu.com.cn/MINI/minicard/3.png',
        path: `/pages/webView/index?webUrl=${window.location.origin}/#/employee-benefit`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
    }
  },

  async created() {
    await this.$getAllInfo()
    initBack()

    const uu = encodeURIComponent(`${window.location.origin}/#/employee-benefit`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
  },
  methods: {
    appBack: newAppBack,
    goService() {
      gotoCustomerService()
    },
    call() {
      call('4007005678')
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  img {
    width: 100%;
  }
  .btn-box {
    width: 100%;
    background-size: 100% 100%;
  }

  .btn-1 {
    height: 411px;
    background-image: url(https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-2.png);
    .b1 {
      margin: 75px 0 0;
    }
  }

  .btn-2 {
    height: 382px;
    background-image: url(https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-5.png);
    .b1 {
      margin: 60px 0 0;
    }
  }

  .btn-3 {
    height: 433px;
    background-image: url(https://img.chaolu.com.cn/ACT/qiyefuli2022/20221014-8.png);
    .b1 {
      margin: 48px 0 0;
    }
  }
  .b1, .b2 {
    width: 472px;
    height: 102px;
  }
  .b2 {
    margin: 62px 0 0;
  }

}
</style>
